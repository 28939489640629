import '../css/book2.scss';
import React, { useState } from 'react';
import détente from '../images/photocentre.jpg'
import { InlineWidget} from "react-calendly";
import logoCouture from '../images/logo.png'



export default function BookSpa(){
    window.scrollTo(0, 0);
    
    const[semaine,setSemaine]=useState(false)
    const[samedi,setSamedi]=useState(false)
    const[dimanche,setDimanche]=useState(false)
    const[validate,setvalidate]=useState(false)
    const[creneau2h,setCreneau2h]=useState(false)
    const[creneau1h30,setCreneau1h30]=useState(false)
    const[creneau3h,setCreneau3h]=useState(false)
    const[creneauFamille,setCreneauFamille]=useState(false)
    const[creneau,setCreneau]=useState("Créneau Spa")
    const[prix, setPrix]=useState(30)

    const[erreur,setErreur]=useState(false)


    const BookDimanche = ()=>{
        if (dimanche){setDimanche(false)}
        else{setDimanche(true)}
        setSemaine(false)
        setSamedi(false)
    }
    const BookSemaine = ()=>{
        if (semaine){setSemaine(false)}
        else{setSemaine(true)}
        setDimanche(false)
        setSamedi(false)
    }

    const BookSamedi = ()=>{
        if (samedi){setSamedi(false)}
        else{setSamedi(true)}
        setDimanche(false)
        setSemaine(false)
    }
    
    const Creneau2h = ()=>{
        setCreneau1h30(false)
        setCreneau3h(false)
        setCreneauFamille(false)
        if(creneau2h){setCreneau2h(false) }
        else{setCreneau2h(true)}
    }

    const Creneau3h = ()=>{
        setCreneau1h30(false)
        setCreneau2h(false)
        setCreneauFamille(false)
        if(creneau2h){setCreneau3h(false) }
        else{setCreneau3h(true)}
    }

    const CreneauFamille = ()=>{
        setCreneau1h30(false)
        setCreneau2h(false)
        setCreneau3h(false)
        if(creneau2h){setCreneauFamille(false) }
        else{setCreneauFamille(true)}
    }

    const Creneau1h30h = ()=>{
        setCreneau2h(false)
        setCreneau3h(false)
        setCreneauFamille(false)
        if(creneau1h30){setCreneau1h30(false)}
        else{setCreneau1h30(true)}
    }
    
    const Validate = ()=>{
        if (!semaine && !dimanche && !samedi){
            setvalidate(false);
            setErreur(true);
        }
        else{
            if(semaine){
                if (creneau1h30) {
                    setCreneau("Créneau Spa - Semaine - 1h30");
                    setPrix(95);
                    setErreur(false);
                    setvalidate(true);
                } else if (creneau2h) {
                    setCreneau("Créneau Spa - Semaine - 2h");
                    setPrix(115);
                    setErreur(false);
                    setvalidate(true);
                } else if (creneau3h) {
                    setCreneau("Créneau Spa - Semaine - 3h");
                    setPrix(160);
                    setErreur(false);
                    setvalidate(true);
                } else {
                    setvalidate(false);
                    setErreur(true);
                }
            } else if(samedi){
                if (creneau2h) {
                    setCreneau("Créneau Spa - Vendredi soir/Samedi - 2h");
                    setPrix(130);
                    setErreur(false);
                    setvalidate(true);
                } else {
                    setvalidate(false);
                    setErreur(true);
                }
            } else if(dimanche){
                if (creneau2h) {
                    setCreneau("Créneau Spa - Dimanche - 2h");
                    setPrix(150);
                    setErreur(false);
                    setvalidate(true);
                } else if (creneauFamille) {
                    setCreneau("Créneau Spa - Semaine - 2h famille");
                    setPrix(160);
                    setErreur(false);
                    setvalidate(true);
                } else {
                    setvalidate(false);
                    setErreur(true);
                }
            }
            
        }
    }  
    



    return(
    <div>
        <p className="titreBookSpa"><b>Réservation - Centre de détente</b></p>
        <p className='subtitleBookSpa'><i>Réservation pour un maximum de 12 personnes</i></p>
        <p className='subtitleBookSpa'><i>Paiement à la réservation : prix du créneau 1 à 2 personnes</i></p>
        <p className='subtitleBookSpa'><i>Les personnes supplémentaires règlent sur place</i></p>
        {
            validate?
            <div className="containerPageCalendar">
                {
                    semaine &&
                        <div>
                            {
                            creneau1h30 &&
                                <div className="calendarContainer">
                                    <div className="containerLeft">
                                        <div className="logoCoutureContainer">
                                            <img src={logoCouture} alt="logoCouture" className="logoCouture"></img>
                                        </div>
                                        <div className="creneauText">{creneau}</div>
                                        <div className="messageText">Délassez vous dans l'espace piscine sauna spa, seul ou à plusieurs.</div>
                                    </div >
                                    <div className="calendar">
                                        <InlineWidget  url="https://calendly.com/coutureducharme-le-spa/semaine-1h30" 
                                            pageSettings={{
                                            backgroundColor: 'ffffff',
                                            hideEventTypeDetails: true,
                                            hideLandingPageDetails: false,
                                            primaryColor: '00a2ff',
                                            textColor: '4d5055'
                                        }}
                                        styles={{
                                            width:"300px",
                                            height:"600px"
                                          }}
                                        />
                                    </div>
                                </div>
                            }
                            {creneau2h &&
                                <div className="calendarContainer">
                                    <div className="containerLeft">
                                        <div className="logoCoutureContainer">
                                            <img src={logoCouture} alt="logoCouture" className="logoCouture"></img>
                                        </div>
                                        <div className="creneauText">{creneau}</div>
                                        <div className="messageText">Délassez vous dans l'espace piscine sauna spa, seul ou à plusieurs.</div>
                                        
                                        <div className="calendar">
                                            <InlineWidget url="https://calendly.com/coutureducharme-le-spa/semaine-2h" 
                                                pageSettings={{
                                                backgroundColor: 'ffffff',
                                                hideEventTypeDetails: true,
                                                hideLandingPageDetails: false,
                                                primaryColor: '00a2ff',
                                                textColor: '4d5055'
                                            }}/>
                                        </div>
                                    </div>
                                </div>
                            }
                            {creneau3h &&
                                <div className="calendarContainer">
                                    <div className="containerLeft">
                                        <div className="logoCoutureContainer">
                                            <img src={logoCouture} alt="logoCouture" className="logoCouture"></img>
                                        </div>
                                        <div className="creneauText">{creneau}</div>
                                        <div className="messageText">Délassez vous dans l'espace piscine sauna spa, seul ou à plusieurs.</div>
                                        
                                        <div className="calendar">
                                            <InlineWidget url="https://calendly.com/coutureducharme-le-spa/semaine-3h" 
                                                pageSettings={{
                                                backgroundColor: 'ffffff',
                                                hideEventTypeDetails: true,
                                                hideLandingPageDetails: false,
                                                primaryColor: '00a2ff',
                                                textColor: '4d5055'
                                            }}/>
                                        </div>
                                    </div>
                                </div>
                            }
                            </div>
                    }
                    {samedi &&
                        <div className="calendarContainer">
                            <div className="containerLeft">
                                <div className="logoCoutureContainer">
                                    <img src={logoCouture} alt="logoCouture" className="logoCouture"></img>
                                </div>
                                <div className="creneauText">{creneau}</div>
                                <div className="messageText">Délassez vous dans l'espace piscine sauna spa, seul ou à plusieurs.</div>
                            </div >
                            <div className="calendar">
                                <InlineWidget url="https://calendly.com/coutureducharme-le-spa/we2" 
                                                    pageSettings={{
                                                    backgroundColor: 'ffffff',
                                                    hideEventTypeDetails: true,
                                                    hideLandingPageDetails: false,
                                                    primaryColor: '00a2ff',
                                                    textColor: '4d5055'
                                }}/>
                            </div>
                        </div>
                    }
                    {dimanche && 
                        <div>
                        {creneau2h && 
                            <div className="calendarContainer">
                                <div className="containerLeft">
                                    <div className="logoCoutureContainer">
                                        <img src={logoCouture} alt="logoCouture" className="logoCouture"></img>
                                    </div>
                                    <div className="creneauText">{creneau}</div>
                                    <div className="messageText">Délassez vous dans l'espace piscine sauna spa, seul ou à plusieurs.</div>
                                    
                                    <div className="calendar">
                                        <InlineWidget url="https://calendly.com/coutureducharme-le-spa/dimanche-feries-2h" 
                                            pageSettings={{
                                            backgroundColor: 'ffffff',
                                            hideEventTypeDetails: true,
                                            hideLandingPageDetails: false,
                                            primaryColor: '00a2ff',
                                            textColor: '4d5055'
                                        }}/>
                                    </div>
                                </div>
                            </div>
                        }
                        {creneauFamille && 
                            <div className="calendarContainer">
                                <div className="containerLeft">
                                    <div className="logoCoutureContainer">
                                        <img src={logoCouture} alt="logoCouture" className="logoCouture"></img>
                                    </div>
                                    <div className="creneauText">{creneau}</div>
                                    <div className="messageText">Délassez vous dans l'espace piscine sauna spa, seul ou à plusieurs.</div>
                                    
                                    <div className="calendar">
                                        <InlineWidget url="https://calendly.com/coutureducharme-le-spa/dimanche-et-jours-feries-2h-famille" 
                                            pageSettings={{
                                            backgroundColor: 'ffffff',
                                            hideEventTypeDetails: true,
                                            hideLandingPageDetails: false,
                                            primaryColor: '00a2ff',
                                            textColor: '4d5055'
                                        }}/>
                                    </div>
                                </div>
                            </div>
                        }
                        </div>
                    }
                    </div>
                    :
        <div className="containerBookSpa" id="containerBook">
            <div className="containerFormSpa">
                <div className="elementForm">
                    <p className={semaine ? "buttonChoiceWeekendClicked" : "buttonChoiceWeekend"}  onClick={()=>BookSemaine()}>En semaine</p>
                    
                    <p className={samedi ? "buttonChoiceWeekendClicked" : "buttonChoiceWeekend"} onClick={()=>BookSamedi()}>Le Vendredi après 17h et Samedi</p>
                    
                    <p className={dimanche ? "buttonChoiceWeekendClicked" : "buttonChoiceWeekend"} onClick={()=>BookDimanche()}>Le Dimanche et jours fériés</p>
                </div>
                {(semaine || samedi || dimanche) && <div className="elementForm">
                    {semaine && <p className={creneau1h30 ? "buttonChoice17hClicked" : "buttonChoice17h"} onClick={()=>Creneau1h30h()}>Créneau 1h30</p>}
                    
                    <p className={creneau2h ? "buttonChoice17hClicked" : "buttonChoice17h"} onClick={()=>Creneau2h()}>Créneau 2h</p>

                    {semaine && <p className={creneau3h ? "buttonChoice17hClicked" : "buttonChoice17h"} onClick={()=>Creneau3h()}>Créneau 3h</p>}

                    {dimanche && <p className={creneauFamille ? "buttonChoice17hClicked" : "buttonChoice17h"} onClick={()=>CreneauFamille()}>Créneau 2h Famille*</p>}
                </div>}
                
                <div className="elementForm">
                    <p className="validateBtn" onClick={()=>Validate()} >Valider</p>
                   
                </div>
                
                
            </div>
            {dimanche && <div style={{"textAlign":"center", "marginTop":"1%", "fontSize":"0.7em"}}>*2h Famille: Créneau de 2h pour 2 adultes et 2 enfants</div>}
            {
                        erreur?<div className="erreurMgs">Certaines informations sont manquantes.</div>:false
                    }
            <div className="containerImgDétente">
                <img src={détente} alt="détente" className="imgDétente"></img>  
            </div>                               
        </div>
        }    
    </div>
    )
}