import * as React from "react";
import {useEffect, useState} from "react";
import "../css/modelling.scss";

import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import CheckoutForm from './checkoutform';

import massage from '../images/massage.jpg';

function MoneyGift(){
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    
    const stripePromise = loadStripe("pk_live_51JKLlzFWy0s3veRrguOQqI3YyzZFWqN8jdcZ5gCbac99FkWWUHLolaEfwhFol5KSjNmzOz6l2i2maf74hjKj0pf600qlwGCGvY") 
    const[invitation, setInvitation]=useState(false);
    const[enveloppe, setEnveloppe]=useState(false);
    const[prix, setPrix]=useState(0);
    const [gift, setGift]=useState(0);
    const[validate,setvalidate]=useState(false);
    const [from, setFrom] = useState("");
    const [to, setTo] = useState("");
    const [mailReceiver, setMailReceiver] = useState("");
    const [mailSender, setMailSender] = useState("");
    const [telReceiver, setTelReceiver] = useState("");
    const [telSender, SetTelSender] = useState("");
    const [message, setMessage] = useState("");
    const [deliveryName, setDeliveryName] = useState("");
    const [adress, setAdress] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [city, setCity] = useState("");
    // eslint-disable-next-line no-unused-vars
    const [creneau, setCreneau] = useState("");
    const [sending, setSending] = useState("");
    
    
    const BookInvitation = ()=>{
        if(!validate){
        if (invitation){setInvitation(false)}
        else{setInvitation(true)}
        setEnveloppe(false)}
    }
    
    const BookEnveloppe = ()=>{
        if(!validate){
        if (enveloppe){setEnveloppe(false)}
        else{setEnveloppe(true)}
        setInvitation(false)}
    }
    
    const Invalidate = ()=>{
        setvalidate(false)
    }
    
    
    const Validate = ()=>{
        if((invitation===false && enveloppe===false)|(from === "")|(to === "")|(mailSender === "")|(telSender === "")|(message === "")|(gift <= 0)){setvalidate(false)}
        else{
            setvalidate(true)
        }
    }
    
    useEffect(() => {
        if (enveloppe){
            setPrix(gift + 2)
            setSending(`Enveloppe carte cadeau ${gift}, livraison: ${deliveryName} ${adress} ${postalCode} ${city}`)
        } else {
            setPrix(gift)
            setSending(`Mail carte cadeau ${gift}`)
        }
    }, [enveloppe, invitation, deliveryName, adress, postalCode, city, gift]);
    
    
    
        return(
            
            <div className="ModellingContainer">
                <div className="modellingFlexRow">
                    <div className="modellingLeft">
                        <img src={massage} alt="spa" className="modellingPic"/>
                        <div>
                            <h1>Carte Cadeau</h1>
                            <p>Envie de faire plaisir ? Nos cartes cadeau vous permettent d'offrir à un proche un moment de bien-être.</p>
                            <p>Vous décidez du montant, il pourra choisir un créneau au spa, un soin en institut, une nuitée...</p>
                            <p>Nos cartes cadeau ont une période de validité de <b>6 mois</b>.</p>
                        </div>
                    </div>
                    <div className="modellingRight">
                    <p><b>Montant carte cadeau :</b></p>
                        <input onChange={e => setGift(parseInt(e.target.value))} style={{textAlign: "center"}} placeholder="Montant"/> €
                        <p><b>Options d'envoi :</b></p>
                        <div className="modellingFlexRow">
                        {
                                invitation?<p className="Clicked2" onClick={()=>BookInvitation()}>Invitation Electronique</p>:<p className="Unclicked2" onClick={()=>BookInvitation()}>Invitation Electronique</p>
                            }
                            {
                                enveloppe?<p className="Clicked2" onClick={()=>BookEnveloppe()}>Enveloppe cadeau</p>:<p className="Unclicked2" onClick={()=>BookEnveloppe()}>Enveloppe cadeau</p>
                            }
                        </div>
                        <p><b>Invitation électronique : </b>envoyée par nos soins par mail sous un délai de 12h</p>
                        <p><b>Enveloppe cadeau : </b>à retirer sur place ou envoyée par nos soins par courrier (+2€)</p>
                        <div className="separation"></div>
                        <p><b>Personnalisez votre cadeau</b></p>
                        <div className="modellingFlexRow">
                            <div className="modellingFlexColumn">
                                <p>De la part de :</p>
                                {!validate?<input onChange={(e) => setFrom(e.target.value)} type="text" id="envoiMassage1" name="massage1" className="envoiMassage"/>:<input type="text" id="envoiMassage1" name="massage1" className="envoiMassage" disabled/>}
                                <p>Mail acheteur :</p>
                                {!validate?<input onChange={(e) => setMailSender(e.target.value)} type="text" id="envoiMassage9" name="massage1" className="envoiMassage"/>:<input type="text" id="envoiMassage9" name="massage1" className="envoiMassage" disabled/>}
                                <p>Tél acheteur:</p>
                                {!validate?<input onChange={(e) => SetTelSender(e.target.value)} type="text" id="envoiMassage10" name="massage1" className="envoiMassage"/>:<input type="text" id="envoiMassage10" name="massage1" className="envoiMassage" disabled/>}
                            </div>
                            <div className="modellingFlexColumn">
                                <p>Offert à :</p>
                                {!validate?<input onChange={(e) => setTo(e.target.value)} type="text" id="envoiMassage2" name="massage1" className="envoiMassage1"/>:<input type="text" id="envoiMassage2" name="massage1" className="envoiMassage1" disabled/>}
                                {!enveloppe ? <><p>Mail destinataire :</p>
                                {!validate?<input onChange={(e) => setMailReceiver(e.target.value)} type="text" id="envoiMassage11" name="massage1" className="envoiMassage1"/>:<input type="text" id="envoiMassage11" name="massage1" className="envoiMassage1" disabled/>}</>: null}
                            </div>
                        </div>
                        {enveloppe?
                            <div>
                                <p>À livrer à (Nom Prénom) :</p>
                                {!validate?<input onChange={(e) => setDeliveryName(e.target.value)} type="text" id="envoiMassage3" name="massage1" className="envoiMassage2"/>:<input type="text" id="envoiMassage3" name="massage1" className="envoiMassage2" disabled/>}
                                
                                {enveloppe && <><p>Tél destinataire :</p>
                                {!validate?<input onChange={(e) => setTelReceiver(e.target.value)} type="text" id="envoiMassage12" name="massage1" className="envoiMassage2"/>:<input type="text" id="envoiMassage12" name="massage1" className="envoiMassage1" disabled/>}</>}

                                <p>Adresse de livraison :</p>
                                {!validate?<input onChange={(e) => setAdress(e.target.value)} type="text" id="envoiMassage4" name="massage1" className="envoiMassage2"/>:<input type="text" id="envoiMassage4" name="massage1" className="envoiMassage2" disabled/>}
                                
                                <div className="modellingFlexRow">
                                    <div className="modellingFlexColumn">
                                        <p>Code postal :</p>
                                        {!validate?<input onChange={(e) => setPostalCode(e.target.value)} type="text" id="envoiMassage5" name="massage1" className="envoiMassage"/>:<input type="text" id="envoiMassage5" name="massage1" className="envoiMassage" disabled/>}
                                        
                                    </div>
                                    <div className="modellingFlexColumn">
                                        <p>Ville :</p>
                                        {!validate?<input onChange={(e) => setCity(e.target.value)} type="text" id="envoiMassage6" name="massage1" className="envoiMassage1" />:<input type="text" id="envoiMassage6" name="massage1" className="envoiMassage1" disabled/>}
                                    
                                    </div>
                                </div>
                            </div>:false}
                        <p>Message personnel (240 caractères maximum) :</p>
                        {!validate?<textarea onChange={(e) => setMessage(e.target.value)} rows="5" cols="50" maxLength="240" className="envoiMassage3"/>:<textarea rows="5" cols="50" maxLength="240" className="envoiMassage3" disabled/>}
                        
                        {
                            !validate?<p onClick={()=>Validate()}  id="envoiMassage7" className="panier">Valider</p>
                            :<p onClick={()=>Invalidate()}  id="envoiMassage8" className="panier">Réinitialiser</p>
                        }              
                        {
                            validate?
                                <div className="PriceBox">
                                <h2 className="giftPrice">{prix}€</h2>
                                <Elements stripe={stripePromise}>
                                <CheckoutForm element="ModelingGift" prix={prix}
                                event="spa"
                                from={from}
                                to={to}
                                mailSender={mailSender}
                                mailReceiver={mailReceiver}
                                telSender={telSender}
                                telReceiver={telReceiver}
                                message={message}
                                creneau={creneau}
                                sending={sending}/>
                                </Elements>
                                <h4 style={{fontWeight: 'normal', margin:"2%"}}>Paiement effectué via <b>Stripe</b></h4>
                            </div>
                            :false
                        }
                    </div>
                </div>
            </div>
    );
};

export default MoneyGift;