import React from 'react'

//On importe le css
import '../css/main.scss'


export default function Reserver(){

    return(
        <div className="BoutonBlack">
                    <a href="/book">Réserver</a>
        </div>

    )
}